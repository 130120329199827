define(['app', 'siteObj', '$console'], (app, siteObj, $console) => {
	const yotiVerificationResult = () => {
		const component = {};

		component.init = (element) => {
			component.element = element;
			component.sessionID = {sessionId: new URLSearchParams(window.location.search).get('sessionId')};
			window.onload = component.getResults();
		};

		component.getResults = () => {
			app.ajax.post({
				url: '/age-verification-results.yoti',
				send: component.sessionID,
				requestHeader: {
					header: 'Content-Type',
					value: 'application/json'
				  },
        		success: component.sendResults,
        		error: component.yotiResultsErrorHandler
			})
		}

		component.sendResults = (response) => {
			let data = JSON.parse(response)
			const event = new CustomEvent('yotiAgeVerificationResults', { detail: data });
			window.parent.document.dispatchEvent(event);
		}

		component.yotiResultsErrorHandler = () => {
			$console.error(`Failed to retrieve results for id: ${component.sessionID.sessionId}`);
		}

		return component;
	};

	return yotiVerificationResult;
});